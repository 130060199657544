import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useWindowSize } from "@src/hooks/useWindowsSize";
import { Sort, TimeSlots, TimeSort } from "@components/Features/Catalog/catalogUtils";
import { numberWithSpaces } from "@src/utils/utils";
import SelectInput from "@components/UI/Inputs/SelectInput/SelectInput";
import HorizontalBtnIcon from "@components/Svg/HorizontalBtnIcon";
import VerticalBtnIcon from "@components/Svg/VerticalBtnIcon";
import VerticalCatalogCard from "@components/Features/Catalog/VerticalCatalogCard/VerticalCatalogCard";
import CatalogCard from "@components/Features/Catalog/CatalogCard/CatalogCard";
import { clearFilters } from "@app/store/filtersSlice";
import { PrimaryButton } from "@components/UI/Buttons/PrimaryButton/PrimaryButton";
import { CatalogListProps } from "./types";
import { AdBlock } from "@shared/ui/AdBlock";
import { SeoTargets } from "@src/services/SeoTargets";
import { AD_BLOCK_1_ID, AD_BLOCK_2_ID, AD_BLOCK_3_ID } from "@shared/ui/AdBlock/constants";
import { ImageStaticAds } from "@shared/ui/ImageStaticAds";
import { STATIC_BANNER_POSITION, UREMONT_BANNER } from "./constants";
import classes from "./CatalogList.module.scss";

const CatalogList = ({
    title = "Последние предложения",
    forceSortShow,
    data,
    loadMore,
    isDataLoading,
    page,
    onFilterChanged,
    hidelShowMore = false,
    hideSort = false,
    allParamsSort = Sort.Popular,
    timeSort = TimeSort.Week,
    onTimeSortChanged,
    onParamsSortChanged,
    showResetFiltersWhenNoData = true,
    showLoadMore = true,
    total,
    defaultTimeSort = TimeSort.AllTime,
    adBlockPositions
}: CatalogListProps) => {
    const dispatch = useDispatch();
    const size = useWindowSize();
    const [currentHoveredCard, setCurrentHoveredCard] = useState(-1);
    const [isVertical, setIsVertical] = useState(true);
    const isOffersVisible = page !== 1 || !isDataLoading;
    const totalStr = numberWithSpaces(total || 0);

    const showMoreClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        SeoTargets.showMoreEvent();
        await loadMore();
    };

    const adBlockIds = [AD_BLOCK_1_ID, AD_BLOCK_2_ID, AD_BLOCK_3_ID];

    const [selectWidth, setSelectWidth] = useState<number | string>(200);
    useEffect(() => {
        if (size.width) {
            if (forceSortShow) {
                if (size.width < 665) {
                    setSelectWidth("100%");
                }
            } else {
                if (size.width < 550) {
                    setSelectWidth(150);
                }
            }
            if (size.width < 1000) {
                setIsVertical(true);
            }
        }
    }, [size.width]);

    return (
        <div className={classes.CatalogList}>
            <div className={`${classes.Header} ${forceSortShow ? classes.ForcedToShow : null}`}>
                <div className={classes.HeaderCont}>
                    <h1>
                        {title}{" "}
                        {total && !isDataLoading ? (
                            <span className={classes.Total}>{totalStr ? totalStr : null}</span>
                        ) : null}
                    </h1>
                </div>

                <div className={`${classes.HeaderMenu}`}>
                    {!hideSort && (
                        <>
                            <SelectInput
                                width={selectWidth}
                                isClearable={allParamsSort === Sort.Popular ? false : true}
                                instanceId={"sort-react-select1231s"}
                                className={classes.Select}
                                value={{ value: allParamsSort, label: allParamsSort }}
                                onChange={(val) => {
                                    if (!val?.value) {
                                        onParamsSortChanged(Sort.Popular);
                                    } else {
                                        onParamsSortChanged(val.value);
                                    }
                                }}
                                isSearchable={false}
                                ariaLabel={"Сортировка по параметрам"}
                                options={[
                                    { value: Sort.Popular, label: Sort.Popular },
                                    { value: Sort.Cheap, label: Sort.Cheap },
                                    { value: Sort.Expensive, label: Sort.Expensive },
                                    { value: Sort.YearNewer, label: Sort.YearNewer },
                                    { value: Sort.YearOlder, label: Sort.YearOlder },
                                    { value: Sort.MileageLess, label: Sort.MileageLess },
                                    { value: Sort.MileageMore, label: Sort.MileageMore },
                                    { value: Sort.ByName, label: Sort.ByName }
                                ]}
                            />

                            <SelectInput
                                isClearable={timeSort !== defaultTimeSort}
                                className={classes.Select2}
                                width={selectWidth}
                                instanceId={"sort2-react-selectasdfsdf3"}
                                value={
                                    timeSort
                                        ? {
                                              value: timeSort,
                                              label: timeSort
                                          }
                                        : { value: defaultTimeSort, label: defaultTimeSort }
                                }
                                onChange={(val) => {
                                    if (val?.value) {
                                        onTimeSortChanged(val?.value);
                                    } else {
                                        onTimeSortChanged(defaultTimeSort);
                                    }

                                    if (onFilterChanged) {
                                        onFilterChanged();
                                    }
                                }}
                                ariaLabel={"Сортировка по времени"}
                                options={TimeSlots}
                            />

                            <div className={classes.Divider}></div>

                            <a
                                className={classes.HorizontalBtn}
                                href="#"
                                aria-label="Горизонтальный вид каталога"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsVertical(false);
                                }}
                            >
                                <HorizontalBtnIcon isActive={!isVertical} />
                            </a>
                            <a
                                className={classes.VerticalBtn}
                                href="#"
                                aria-label="Вертикальный вид каталога"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsVertical(true);
                                }}
                            >
                                <VerticalBtnIcon isActive={isVertical} />
                            </a>
                        </>
                    )}
                </div>
            </div>
            {/* TODO: Временно скрываем cсылки на оценку авто. Для подробностей см. задачу LIKVI-1346 в Jira.
            <AnalyticsBanner /> */}
            <ul
                className={`${classes.CardList} ${
                    isVertical ? classes.Vertical : classes.Horizontal
                }`}
            >
                {isOffersVisible &&
                    Array.isArray(data) &&
                    data.map((item, index) => {
                        const isPriorityCard = index < 4;
                        const isLastItem = index === data.length - 1;
                        const isNeighborBelow = index === currentHoveredCard - 1;
                        const showDivider = !isLastItem && !isNeighborBelow;
                        const adBlockPositionIndex = (adBlockPositions || []).indexOf(index);
                        const isAdBlockPosition = adBlockPositionIndex !== -1;

                        return (
                            <React.Fragment key={item.id}>
                                <li>
                                    {isVertical ? (
                                        <VerticalCatalogCard
                                            data={item}
                                            isPriorityCard={isPriorityCard}
                                        />
                                    ) : (
                                        <CatalogCard
                                            data={item}
                                            showDivider={showDivider}
                                            setIsHovered={(isHovered) =>
                                                setCurrentHoveredCard(isHovered ? index : -1)
                                            }
                                        />
                                    )}
                                </li>
                                {isVertical && isAdBlockPosition && (
                                    <li key={`adblock-${index}`}>
                                        {title === "Последние предложения" &&
                                        adBlockPositionIndex === STATIC_BANNER_POSITION ? (
                                            <ImageStaticAds
                                                src={UREMONT_BANNER.SRC}
                                                alt={UREMONT_BANNER.ALT}
                                                href={UREMONT_BANNER.LINK}
                                                title={UREMONT_BANNER.TITLE}
                                            />
                                        ) : (
                                            <AdBlock blockId={adBlockIds[adBlockPositionIndex]} />
                                        )}
                                    </li>
                                )}
                            </React.Fragment>
                        );
                    })}
                {isOffersVisible && !isDataLoading && data?.length === 0 && (
                    <div className={classes.Error}>
                        По данным критериям не нашлось объявлений.{" "}
                        {showResetFiltersWhenNoData && (
                            <>
                                <br />
                                <a
                                    style={{
                                        color: "#3B85F7"
                                    }}
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(clearFilters());
                                        onFilterChanged?.();
                                    }}
                                >
                                    Cбросить фильтр?
                                </a>
                            </>
                        )}
                    </div>
                )}
            </ul>

            {isDataLoading && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <div className="custom-loader">loading</div>
                </div>
            )}

            {isOffersVisible && !hidelShowMore && data?.length !== 0 && showLoadMore && (
                <PrimaryButton
                    onClick={showMoreClick}
                    isLoading={isDataLoading}
                    className={classes.PrimaryButton}
                    content={<>Показать еще</>}
                />
            )}
        </div>
    );
};
export default CatalogList;